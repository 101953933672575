<template>
    <div class="meal-kit-container">
        <h1 class="title">
            {{ $t('mealKits.title') }}
        </h1>
        <CRButton class="btn" @click="toCreatePage">
            {{ $t('buttons.addNewKit') }}
        </CRButton>
        <div class="kit__wrapper">
            <h2>
                {{ $t('mealKits.regularKits') }}
            </h2>
            <p v-if="!regularKits.length">
                {{ $t('mealKits.noKitsAdded') }}
            </p>
            <MealKitCard
                v-else
                class="card"
                @click="onMealKitClick(mealKit.id)"
                is-admin-view
                v-for="mealKit in regularKits"
                :key="mealKit.id"
                :id="mealKit.id"
                :name="$filters.getTranslationByKey(mealKit.name)"
                :price="mealKit.calories[0].prices[0].price"
                :meals-amount="mealKit.mealsAmount"
                :is-vegetarian="mealKit.isVegetarian"
                :discount-type="mealKit.discount?.type"
                :discount-amount="mealKit.discount?.amount"
                :is-hidden="mealKit.isHidden"
                :image="mealKit.pictures[0]?.picturePath"
                @delete="openDeleteModal"
                @hide="changeMealKitVisibility"
            />
        </div>
        <div class="kit__wrapper">
            <h2>
                {{ $t('mealKits.uniqueKits') }}
            </h2>
            <p v-if="!uniqueKits.length">
                {{ $t('mealKits.noKitsAdded') }}
            </p>
            <MealKitCard
                v-else
                class="card"
                @click="onMealKitClick(mealKit.id)"
                is-admin-view
                v-for="mealKit in uniqueKits"
                :key="mealKit.id"
                :id="mealKit.id"
                :name="$filters.getTranslationByKey(mealKit.name)"
                :price="mealKit.price"
                :meals-amount="mealKit.mealsAmount"
                :is-vegetarian="mealKit.isVegetarian"
                :discount-type="mealKit.discount?.type"
                :discount-amount="mealKit.discount?.amount"
                :is-hidden="mealKit.isHidden"
                is-unique
                :image="mealKit.pictures[0]?.picturePath"
                @delete="openDeleteModal"
                @hide="changeMealKitVisibility"
            />
        </div>
        <div class="kit__wrapper" v-if="individualOrderSettings">
            <h2>
                {{ $t('individualOrder.title') }}
            </h2>
            <MealKitCard
                class="card"
                :name="$t('individualOrder.title')"
                :discount-type="individualOrderSettings.discount?.type"
                :discount-amount="individualOrderSettings.discount?.amount"
                :is-hidden="individualOrderSettings.isHidden"
                :image="individualOrderSettings.picture?.picturePath"
                is-admin-view
                @click="onIndividualOrderClick"
                is-individual
            />
        </div>
        <DeleteConfirmationModal
            v-if="isDeleteModalOpen"
            :title="`${$t('mealKits.kit')} - “${deletedMealKit.name}”`"
            @close="closeDeleteModal"
            @delete="deleteMealKit"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import MealKitCard from '@/components/MealKits/MealKitCard.vue';
    import DeleteConfirmationModal from '@/components/Modals/DeleteConfirmationModal.vue';

    export default {
        name: 'AllMealKits',
        components: { CRButton, MealKitCard, DeleteConfirmationModal },
        data() {
            return {
                isDeleteModalOpen: false,
                deletedMealKit: null,
            };
        },
        computed: {
            ...mapGetters('mealKit', ['regularKits', 'uniqueKits', 'individualOrderSettings']),
        },
        methods: {
            ...mapActions('mealKit', [
                'getAllMealKits',
                'destroyMealKit',
                'changeMealKitVisibility',
                'getIndividualOrderSettings',
            ]),

            onMealKitClick(id) {
                this.$router.push({
                    name: 'updateMealKit',
                    params: { meal_kit_id: id },
                });
            },

            onIndividualOrderClick() {
                this.$router.push({ name: 'updateIndividualOrder' });
            },

            openDeleteModal(data) {
                this.isDeleteModalOpen = true;
                this.deletedMealKit = data;
            },

            closeDeleteModal() {
                this.isDeleteModalOpen = false;
                this.deletedMealKit = null;
            },

            async deleteMealKit() {
                try {
                    await this.destroyMealKit(this.deletedMealKit);
                    this.closeDeleteModal();
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            toCreatePage() {
                this.$router.push({ name: 'createMealKit' });
            },
        },
        async mounted() {
            try {
                await this.getAllMealKits();
                await this.getIndividualOrderSettings();
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    };
</script>

<style lang="scss" scoped>
    .meal-kit-container {
        h1 {
            display: none;
        }

        .btn {
            margin-top: 20px;
        }

        .kit__wrapper {
            @include row-align-stretch;
            flex-wrap: wrap;

            margin-top: 22px;

            .card {
                width: 100%;
                margin-bottom: 20px;

                &:hover {
                    transform: scale(1);
                }
            }

            h2 {
                margin-bottom: 20px;
                width: 100%;
            }

            p {
                font-style: italic;
                color: $grey-form-label;
            }
        }
    }

    @include media($md) {
        .meal-kit-container {
            .kit__wrapper {
                @include row-align-stretch-justify-between;

                .card {
                    width: 49%;
                }
            }
        }
    }

    @include media($xl) {
        .meal-kit-container {
            .kit__wrapper {
                @include row-align-stretch-justify-start;

                .card {
                    width: 32%;
                    margin-right: 2%;

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>
